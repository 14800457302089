// src/App.js
import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { getAuth, onAuthStateChanged } from 'firebase/auth';
import Dashboard from './Pages/Dashboard';
import Login from './Pages/Login';
import Report from './Pages/Report';
import Theme from './Components/core/Theme';
import '@fontsource/inter';
import { SocketProvider } from './socket/SocketContext';
import './conf/firebaseConfig'; // this ensures firebase is loaded

const App = () => {
  const [currentUser, setCurrentUser] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const auth = getAuth();
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      setCurrentUser(user);
      setLoading(false);
    });

    // Cleanup subscription on unmount
    return () => unsubscribe();
  }, []);

  if (loading) {
    return <div>Loading...</div>; // or some loading spinner
  }

  {/** No current user, login */}
  if (!currentUser) {
    return (
      <Router>
        <Theme>
            <Login />
        </Theme>
      </Router>
    )
  }

  return (
    <Router>
        <Theme>
            <Routes>
                <Route exact path="/" Component={Dashboard} />
                <Route path="/report/:id" element={<Report />} /> 
            </Routes>
        </Theme>
    </Router>
  );
};

const AppWithContext = () => (
  <SocketProvider>
      <App />
  </SocketProvider>
)

export default AppWithContext;