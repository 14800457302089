import React, { useState, useEffect, useContext } from 'react';
import { useSocket } from '../../socket/SocketContext';

export const ReportContext = React.createContext();

export const stages = [
    'Stage 1: Crawling Sitemaps',
    'Stage 2: Generating Lighthouse Report',
    // 'Stage 3: Testing Sitemap Health',
    'Stage 3: Generating Report',
    'Stage 4: Saving Report',
    'Stage 5: Complete',
];

export const ReportProvider = ({ children }) => {
  
  const socket = useSocket();
  const [IsGeneratedUID, setIsGeneratedUID] = useState(false);
  const [sitemap, setSitemap] = useState([]);
  const [log, setLog] = useState([]);
  const [sitemapHealthStats, setSitemapHealthStats] = useState(null);
  const [stage, setStage] = useState(0);
  const [params, setParams] = useState({});
  const [error, setError] = useState(false);

  useEffect(() => {
    if (socket == null) return;

    socket.on('reportGenerated', (data) => {
      setIsGeneratedUID(data);
    });

    socket.on('sitemapGenerated', (data) => {
        setSitemap(data);
    });

    socket.on('reportError', (message) => {
      handleReportFailed(message);
    });

    socket.on('error', (data) => {
      console.log('error', data);
    });

    socket.on('reportUpdate', (data) => {
      setLog((prevLog) => [...prevLog, data]);
    });

    socket.on('reportSitemapStats', (data) => {
      setSitemapHealthStats(data);
    });

    socket.on('reportStage', (data) => {
      setStage(data);
    });

    return () => {
      socket.off('error');
      socket.off('reportGenerated');
      socket.off('reportUpdate');
      socket.off('reportSitemapStats');
      socket.off('reportStage');
    };
  }, [socket]);

  const handleSubmit = (p) => {
    setParams(p);
    socket.emit('generateSitemap', p);
    setLog([]);
    setSitemapHealthStats(null);
    setStage(0);
  }

  const handleGenerateReport = () => {
    const p = {...params, crawlSitemaps: sitemap}
    socket.emit('generateReport', p);
    setStage(2);
  }

  const handleCancel = () => {
    socket.emit('cancelReport', true);
    console.log('cancelling report');
    /* setLog([]);
    setSitemapHealthStats(null);
    setStage([]); */
  }

  const handleReportFailed = (message) => {
    setError(message);
    setLog([]);
    setSitemapHealthStats(null);
    setStage(0);
  }

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setError(false);
  };

  return (
    <ReportContext.Provider value={{ 
        IsGeneratedUID, 
        setIsGeneratedUID, 
        log, 
        setLog, 
        sitemapHealthStats, 
        setSitemapHealthStats, 
        stage, 
        setStage, 
        error, 
        setError,
        handleSubmit,
        handleCancel,
        handleClose,
        sitemap,
        setSitemap,
        handleGenerateReport
    }}>
      {children}
    </ReportContext.Provider>
  );
};

export const useReportGenerator = () => {
    return useContext(ReportContext);
};
  