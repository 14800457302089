const env = process.env.REACT_APP_ENV || 'remote';

//console.log('process.env.REACT_APP_ENV', process.env.REACT_APP_ENV);

const local = {
	AGENT_API: `http://localhost:3002`,
};

const remote = {
	AGENT_API: `https://api.ops.u7wpdev.com/`,
};

const config = {
	local,
	remote
};

module.exports = config[env];