import React from 'react';
import { Typography } from '@mui/material';
import Form from '../../../Components/global/Form';
import { url } from '../../../Components/validation';
import { useReportGenerator } from '../context';

export const BeforeSubmit = () => {
    const { handleSubmit } = useReportGenerator();
  
    return (
      <React.Fragment>
        <Typography variant="h3">Generate New Report</Typography>
          <Form 
            onSubmit={handleSubmit}
            template={{
              prod: {
                  type: 'text',
                  placeholder: 'Enter production URL',
                  validate: url,

              },
              dev: {
                  type: 'text',
                  placeholder: 'Enter development URL',
                  validate: url,

              },
              uname: {
                type: 'text',
                placeholder: 'Enter User (optional)',

              },
              pwd: {
                type: 'text',
                placeholder: 'Enter Password (optional)',

              },
              /* sitemap: {
                type: 'text',
                placeholder: 'CSS Selector',
              } */
          }}
          />
      </React.Fragment>
    )
  }