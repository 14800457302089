import React from 'react';
import { ReportProvider, useReportGenerator, stages } from './GenerateReport/context';
import Snackbar from './GenerateReport/Snackbar';
import { BeforeSubmit } from './GenerateReport/stages/BeforeSubmit';
import { Stages } from './GenerateReport/stages/Stages';
import { Complete } from './GenerateReport/stages/Complete';
import { SitemapSelector } from './GenerateReport/stages/SitemapSelector';

const Dashboard = () => {
  const { stage, sitemap } = useReportGenerator();
  //console.log('stage', stage, stage === stages.length, stages.length);
  return (
    <ReportProvider>
      <div>
        {/** Error Snackbar Handler */}
        <Snackbar />
        {/** View Report */}
        {stage === stages.length && (
          <Complete />
        )}
        {/** Before Submit */}
        {stage < 1 && (
          <BeforeSubmit />
        )}
        {/** Sitemap Selector */}
        {stage == 1 && !!sitemap.length && (
          <SitemapSelector />
        )}
        {/** Stages */}
        {stage > 0 && (
          <Stages />
        )}
      </div>
    </ReportProvider>
  );
};

const DashboardProvider = () => {
  return (
    <ReportProvider>
      <Dashboard />
    </ReportProvider>
  );
};

export default DashboardProvider;