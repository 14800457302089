import React, { useState } from 'react';
import { Checkbox, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography, Modal, Card, Button, Box } from '@mui/material';
import { useReportGenerator } from '../context';

export const SitemapSelector = () => {
  const { sitemap, setSitemap, handleGenerateReport } = useReportGenerator();
  const [open, setOpen] = useState(true);

  const handleCheck = (url) => {
    setSitemap(prev => {
      return prev.map(item => {
        if (item.url === url) {
          return { ...item, active: !item.active };
        } else {
          return item;
        }
      });
    });
  };
  
  const handleClose = () => {
    setOpen(false);
    handleGenerateReport();
  };

  return (
    <Modal
    open={open}
    onClose={handleClose}
    aria-labelledby="sitemap-selector-title"
    aria-describedby="sitemap-selector-description"
    >
    <Card style={{ 
      backgroundColor: '#fff', 
      padding: '20px', 
      maxWidth: '80vw', 
      maxHeight: '80vh', 
      overflow: 'auto',
      margin: '10vh auto'
    }}>
        <Box display="flex" justifyContent="space-between" alignItems="center" marginBottom={2}>
            <Typography variant="h4" id="sitemap-selector-title">Select Crawl Candidates</Typography>
            {!!sitemap.length && (
                <Button variant="contained" color="primary" onClick={handleClose}>
                    Continue
                </Button>
            )}
        </Box>
        <TableContainer>
        <Table>
            <TableHead>
            <TableRow>
                <TableCell padding="checkbox"></TableCell>
                <TableCell>URL</TableCell>
            </TableRow>
            </TableHead>
            <TableBody>
            {sitemap.map((item, index) => (
                <TableRow key={index}>
                <TableCell padding="checkbox">
                    <Checkbox
                    checked={item.active}
                    onChange={() => handleCheck(item.url)}
                    />
                </TableCell>
                <TableCell>{item.url}</TableCell>
                </TableRow>
            ))}
            </TableBody>
        </Table>
        </TableContainer>
    </Card>
    </Modal>
  );
};