import { createTheme } from '@mui/material/styles';

const muiTheme = createTheme({
  typography: {
    allVariants: {
      marginTop: '15px',
      marginBottom: '15px',
    },
  },
  components: {
    // Override styles for TextField
    MuiTextField: {
      styleOverrides: {
        root: {
          display: 'block', // Make TextField appear on a new line
          marginBottom: 0, // Add space after each TextField
        },
      },
    },
  },
});

export default muiTheme;