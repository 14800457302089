import React, { useState, useEffect } from 'react';
import { Button, TextField, InputAdornment, IconButton } from '@mui/material';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';

const Form = ({ template, onSubmit = () => null }) => {
  const [formValues, setFormValues] = useState({});
  const [errors, setErrors] = useState({});
  const [showPassword, setShowPassword] = useState({});

  useEffect(() => {
    // Initialize form values and showPassword state
    const initialFormValues = {};
    const initialShowPassword = {};
    Object.keys(template).forEach((field) => {
      initialFormValues[field] = template[field].value || '';
      initialShowPassword[field] = false;
    });
    setFormValues(initialFormValues);
    setShowPassword(initialShowPassword);
  }, [template]);

  const defaultValidation = (value, type) => {
    switch (type) {
      case 'email':
        return /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(value);
      case 'password':
        return value.length >= 6;
      case 'number':
        return !isNaN(value) && Number.isFinite(value);
      default:
        // default string, test for no script
        return !/<script\b[^<]*(?:(?!<\/script>)<[^<]*)*<\/script>/gi.test(value);
    }
  };

  const handleChange = (event) => {
    setFormValues({ ...formValues, [event.target.name]: event.target.value });
  };

  const handleToggleShowPassword = (field) => {
    setShowPassword({ ...showPassword, [field]: !showPassword[field] });
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    let validationErrors = {};
    Object.keys(template).forEach((field) => {
      const value = formValues[field];
      const validationFunction = template[field].validate || (() => defaultValidation(value, template[field].type));
      if (!validationFunction(value)) {
        validationErrors[field] = 'Invalid value';
      }
    });

    setErrors(validationErrors);

    if (Object.keys(validationErrors).length === 0) {
      // Handle form submission
      onSubmit(formValues);
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      {Object.keys(template).map((field) => (
        <TextField
          key={field}
          name={field}
          type={template[field].type === 'password' && !showPassword[field] ? 'password' : 'text'}
          label={template[field].placeholder || field}
          value={formValues[field]}
          onChange={handleChange}
          placeholder={template[field].placeholder}
          error={!!errors[field]}
          helperText={errors[field] || ''}
          InputProps={
            template[field].type === 'password'
              ? {
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={() => handleToggleShowPassword(field)}
                        edge="end"
                      >
                        {showPassword[field] ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }
              : {}
          }
        />
      ))}
      <Button type="submit" variant="contained" color="primary">
        Submit
      </Button>
    </form>
  );
};

export default Form;