import React, { useEffect } from 'react';
import { Button } from '@mui/material';
import { getAuth, signInWithPopup, GoogleAuthProvider } from 'firebase/auth';

const Login = () => {
  const auth = getAuth();

  useEffect(() => {
    auth.onAuthStateChanged((user) => {
      if (user) {
        // Redirect to the dashboard
        window.location.href = '/';
      }
    });
  }, [auth]);

  const handleLogin = () => {
    const provider = new GoogleAuthProvider();
    signInWithPopup(auth, provider).catch((error) => {
      // Handle Errors here.
      console.error(error);
    });
  };

  return (
    <div>
      <Button variant="contained" onClick={handleLogin}>
        Login with Google
      </Button>
    </div>
  );
};

export default Login;