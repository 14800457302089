import React, { createContext, useState, useEffect, useContext } from 'react';
import { getAuth, signOut } from 'firebase/auth';
import { io } from 'socket.io-client';
import config from '../config';

const SocketContext = createContext();

export const SocketProvider = ({ children }) => {
  const [socket, setSocket] = useState(null);
  const auth = getAuth();

  const WEBSITE_DOMAIN = config.AGENT_API;

  useEffect(() => {
    auth.onAuthStateChanged((user) => {
      if (user) {
        user.getIdToken().then((token) => {
          const newSocket = io(WEBSITE_DOMAIN, {
            auth: {
              token,
            },
          });

          newSocket.on('connect_error', (reason) => {
            console.log('reason', reason);
            /* signOut(auth).then(() => {
              // alert('Disconnected.');
              //window.location = '/login';
            }); */
          });
          
          setSocket(newSocket);

          return () => newSocket.close();
        });
      }
    });
  }, [auth, WEBSITE_DOMAIN]);

  return (
    <SocketContext.Provider value={socket}>
      {children}
    </SocketContext.Provider>
  );
};

export const useSocket = () => useContext(SocketContext);