import React, { useEffect, useRef } from 'react';
import { Typography, Box, Button, List, ListItem, ListItemIcon, ListItemText, Grid } from '@mui/material';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';
import { useReportGenerator, stages } from '../context';

export const Stages = () => {
    const { 
      IsGeneratedUID, 
      log, 
      setLog, 
      sitemapHealthStats, 
      stage, 
      handleCancel,
    } = useReportGenerator();
    const logBoxRef = useRef(null);
    useEffect(() => {
      if (!logBoxRef.current) return;
      // Scroll to the bottom of the log box
      logBoxRef.current.scrollIntoView({ behavior: 'smooth' });
    }, [log, setLog])
    
    return (
      <React.Fragment>
        <Typography variant="h3">Generating Report</Typography>
        {log.length > 0 && (
          <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
              <Box bgcolor="grey" color="white" p={2} sx={{maxHeight: '500px', overflowY: 'scroll', overscrollBehaviorY: 'contain', scrollSnapType: 'y proximity'}}>
                {log.map((message, index) => (
                  <p key={index}>{message}</p>
                ))}
                <div ref={logBoxRef} />
              </Box>
            </Grid>
            <Grid item xs={12} md={6}>
              <List>
                {stages.map((stageItem, index) => (
                  <ListItem key={index} sx={{padding: '2px 0'}}>
                    <ListItemIcon>
                      {index < stage ? <CheckCircleIcon color="success" /> : <RemoveCircleIcon color="disabled" />}
                    </ListItemIcon>
                    <ListItemText primary={stageItem} />
                  </ListItem>
                ))}
                {!IsGeneratedUID && (
                  <Button variant="contained" color="secondary" onClick={handleCancel}>
                    Cancel Report
                  </Button>
                )}
              </List>
              {sitemapHealthStats && (
                <Box>
                  <Typography variant="h6">Sitemap Health Stats</Typography>
                  <p>Prod Unhealthy Percentage: {sitemapHealthStats.unhealthyProdPercentage}%</p>
                  <p>Dev Unhealthy Percentage: {sitemapHealthStats.unhealthyDevPercentage}%</p>
                  {sitemapHealthStats.unhealthyProdUrls.length > 0 && (
                    <React.Fragment>
                      <Typography variant="subtitle1">Unhealthy Prod URLs:</Typography>
                      <List>
                        {sitemapHealthStats.unhealthyProdUrls.map((url, index) => (
                          <ListItem key={index}>
                            <ListItemText primary={url} />
                          </ListItem>
                        ))}
                      </List>
                    </React.Fragment>
                  )}
                  {sitemapHealthStats.unhealthyDevUrls.length > 0 && (
                    <React.Fragment>
                      <Typography variant="subtitle1">Unhealthy Dev URLs:</Typography>
                      <List>
                        {sitemapHealthStats.unhealthyDevUrls.map((url, index) => (
                          <ListItem key={index}>
                            <ListItemText primary={url} />
                          </ListItem>
                        ))}
                      </List>
                    </React.Fragment>
                  )}
                </Box>
              )}
            </Grid>
          </Grid>  
        )}
      </React.Fragment>
    );
  }