import React, { useState, useEffect, useContext } from 'react';
import { AppBar, Button, Toolbar, Typography, IconButton, Drawer, List, ListItem, ListItemText, ListItemIcon, Divider, Menu, MenuItem, Avatar, Collapse, ListSubheader } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import AccountCircle from '@mui/icons-material/AccountCircle';
import CloseIcon from '@mui/icons-material/Close';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import { Link, useLocation } from 'react-router-dom'; 
import { useSocket } from '../../socket/SocketContext';
import { Helmet } from 'react-helmet';

const drawerWidth = 240;

const Theme = ({ children }) => {
  const [mobileOpen, setMobileOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [reports, setReports] = useState([]);
  const [groupedReports, setGroupedReports] = useState([]);
  const [openReports, setOpenReports] = useState({});
  const socket = useSocket();
  const location = useLocation(); // Get the current location

  useEffect(() => {
    // Close the sidebar drawer when the location changes
    setMobileOpen(false);
  }, [location]); 

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const handleUserMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseUserMenu = () => {
    setAnchorEl(null);
  };

  useEffect(() => {
    if (!socket) return;

    socket.on('reports', (reports) => {
      setReports(reports);
    });

    socket.on('reportGenerated', (success) => {
      if (success) {
        socket.emit('getReports');
      }
    })

    // Request the reports when the component mounts
    socket.emit('getReports');

    return () => {
      // Clean up the listener when the component unmounts
      socket.off('reports');
      socket.off('reportGenerated');
    };
  }, [socket]);

  useEffect(() => {
    if (!reports) return;
    // Group reports by site
    const grouped = reports.reduce((grouped, report) => {
      (grouped[report.site] = grouped[report.site] || []).push(report);
      return grouped;
    }, {});
    setGroupedReports(grouped);
  }, [reports]);

  const handleToggleReports = (site) => {
    setOpenReports({ ...openReports, [site]: !openReports[site] });
  };

  const drawer = (
    <div>
      <Toolbar>
        <IconButton edge="end" color="inherit" onClick={handleDrawerToggle} sx={{ marginLeft: 'auto' }}>
          <CloseIcon />
        </IconButton>
      </Toolbar>
      <Divider />
      <Typography variant="h6" style={{ flexGrow: 1, padding: '0 15px' }}>
        Site Reports
      </Typography>
      <Divider />
      <List>
      {Object.entries(groupedReports).sort().map(([site, siteReports], index) => (
        <div key={index}>
          <ListItem button onClick={() => handleToggleReports(site)}>
            <ListItemText primary={site} />
            {openReports[site] ? <ExpandLess /> : <ExpandMore />}
          </ListItem>
          <Collapse in={openReports[site]} timeout="auto" unmountOnExit>
            <List component="div" disablePadding>
              {siteReports.map((report, index) => {
                const date = new Date(report.createdAt._seconds * 1000);
                const dateString = date.toLocaleString();
                return (
                  <ListItem button key={index} component={Link} to={`/report/${report.id}`}>
                    <ListItemText primary={`@ ${dateString}`} />
                  </ListItem>
                );
              })}
            </List>
          </Collapse>
        </div>
      ))}
      </List>
      <Divider />
      <Button variant="contained" component={Link} to={'/'} style={{marginLeft: 10}}>
        Generate New Report
      </Button>
    </div>
  );

  return (
      <div>
        <Helmet>
          <title>U7 WebOps</title>
          <meta name="description" content="U7 Solutions WebOps Dashboard" />
        </Helmet>
        <AppBar position="fixed" style={{ marginLeft: mobileOpen ? drawerWidth : 0, width: `calc(100% - ${mobileOpen ? drawerWidth : 0}px)` }}>
          <Toolbar>
            <IconButton edge="start" color="inherit" aria-label="menu" onClick={handleDrawerToggle}>
              <MenuIcon />
            </IconButton>
            <Typography variant="h6" style={{ flexGrow: 1 }}>
              U7 DevSecOps Dashboard
            </Typography>
            <IconButton color="inherit" onClick={handleUserMenu}>
              <AccountCircle />
            </IconButton>
            <Menu
              anchorEl={anchorEl}
              open={Boolean(anchorEl)}
              onClose={handleCloseUserMenu}
            >
              <MenuItem onClick={handleCloseUserMenu}>Profile</MenuItem>
              <MenuItem onClick={handleCloseUserMenu}>Logout</MenuItem>
            </Menu>
          </Toolbar>
        </AppBar>
        <Drawer
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          style={{ width: drawerWidth }}
          PaperProps={{ style: { width: drawerWidth } }}
        >
          {drawer}
        </Drawer>
        <main style={{ padding: "40px", marginTop: 56, marginLeft: mobileOpen ? drawerWidth : 0, maxWidth: '1200px' }}>
          {children}
        </main>
      </div>
  );
};

export default Theme;