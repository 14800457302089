import React, { useState, useEffect } from 'react';
import { Accordion, AccordionSummary, AccordionDetails, Typography, Grid, Paper, Divider, Card, CardContent, Tabs, Tab, Box, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import CheckCircle from '@mui/icons-material/CheckCircle';
import { useParams } from 'react-router-dom'; // Import useParams
import { useSocket } from '../socket/SocketContext';
import ImgWithModal from '../Components/global/ImgWithModal';

function Report() {
    const DIFF_THRESHOLD = 5; // Set the diff threshold

    const { id } = useParams(); // Get the id from the URL
    const [reportData, setReportData] = useState([]);
    const [loading, setLoading] = useState(true); 
    const [tabValue, setTabValue] = useState(0);
    const socket = useSocket();
  
    useEffect(() => {
        // setLoading(true);
      if (!socket) return;
  
      socket.on(`report`, (report) => {
        report.report.sort((a, b) => b.diff.percent - a.diff.percent);
  
        // Create report.warning and report.success based on DIFF_THRESHOLD
        report.warning = report.report.filter(item => item.diff.percent >= DIFF_THRESHOLD);
        report.success = report.report.filter(item => item.diff.percent < DIFF_THRESHOLD);
  
        // for dev
        // report.warning = report.report;

        setReportData(report);
        setLoading(false);
      });
  
      // Request the report when the component mounts
      socket.emit('getReport', id); // Send the id to the backend
  
      return () => {
        // Clean up the listener when the component unmounts
        socket.off('report');
      };
    }, [socket, id]); 

    // Screen tabs
    const handleTabChange = (event, newValue) => {
        setTabValue(newValue);
    };

    // lighthouse score
    const getScoreColor = (score) => {
        if (score < 50) return '#ff3333';
        if (score < 90) return '#ffaa33';
        return '#00cc66';
    };
    
    const date = new Date(reportData?.createdAt?._seconds * 1000);
    const dateString = date.toLocaleString();

    return (
        <div style={{maxWidth: '1350px', margin: '0 auto'}}>
            <Typography variant="h4" gutterBottom>
                {reportData.site} report @ {dateString} 
            </Typography>
            <Divider />
            <Tabs value={tabValue} onChange={handleTabChange}>
                <Tab label="Lighthouse" sx={{fontWeight: tabValue === 0 ? 'bold' : 'normal'}} />
                <Tab label="Diffs" sx={{fontWeight: tabValue === 1 ? 'bold' : 'normal'}} />
                <Tab label="Site Health" sx={{fontWeight: tabValue === 2 ? 'bold' : 'normal'}} />
            </Tabs>
            <Box sx={{ padding: 2 }}>
                {tabValue === 0 && (
                    <React.Fragment>
                        <Grid container spacing={2}>
                            {['mobile', 'desktop'].map((type) => (
                                <Grid item xs={12} md={6} key={type}>
                                    <Typography variant="h5" mt={4} gutterBottom>
                                        {type.charAt(0).toUpperCase() + type.slice(1)} Scores
                                    </Typography>
                                    <Divider sx={{marginBottom: 2}} />
                                    <Grid container spacing={2}>
                                        {reportData.lhr && Object.entries(reportData.lhr[type].categories).map(([key, value]) => (
                                            <Grid item xs={12} md={4} key={key}>
                                                <Card sx={{ marginBottom: 0, paddingBottom: 0, height: '100%' }}>
                                                    <CardContent>
                                                        <Typography variant="h6" mb={0}>
                                                            {key.charAt(0).toUpperCase() + key.slice(1)}: <br /><span style={{fontSize: 55, color: getScoreColor(value.score * 100)}}>{Math.round(value.score * 100)}</span>
                                                        </Typography>
                                                    </CardContent>
                                                </Card>
                                            </Grid>
                                        ))}
                                    </Grid>
                                </Grid>
                            ))}
                        </Grid>
                        <Divider sx={{marginBottom: 2, marginTop: 2}} />
                        <TableContainer component={Paper} sx={{ marginTop: 2 }}>
                            <Table>
                                <TableHead>
                                    <TableRow>
                                        <TableCell>Key</TableCell>
                                        <TableCell align="right">Score</TableCell>
                                        <TableCell align="right">Description</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {reportData.lhr && Object.entries(reportData.lhr['mobile'].audits).map(([key, value]) => (
                                        <TableRow key={key}>
                                            <TableCell component="th" scope="row">
                                                {key}
                                            </TableCell>
                                            <TableCell align="right">{Math.round(value.score * 100)}</TableCell>
                                            <TableCell align="right">{value.description}</TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </React.Fragment>
                )}
                {tabValue === 1 && (
                    <React.Fragment>
                    <Typography variant="h5" mt={4} gutterBottom>
                        Warnings ({reportData?.warning?.length || 0})
                    </Typography>
                    <Divider />
                    {reportData?.warning && reportData.warning.map((item, index) => (
                        <Accordion key={index}>
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls={`panel${index}a-content`}
                                id={`panel${index}a-header`}
                            >
                                <Typography sx={{ flexShrink: 0 }}>
                                    {item.prod.url}
                                </Typography>
                                <Typography sx={{marginLeft: 'auto', paddingRight: '25px'}}>
                                    Difference: {parseFloat(item.diff.percent).toFixed(2)}%
                                </Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <Grid container spacing={2}>
                                    <Grid item xs={4}>
                                        <Paper elevation={3}>
                                            <Typography variant="h6" mt={0} p={1} align="center" bgcolor={"#eee"}>
                                                Prod
                                            </Typography>
                                            <ImgWithModal src={item.prod.image} alt="Production" style={{ width: '100%', cursor: 'pointer' }} />
                                        </Paper>
                                    </Grid>
                                    <Grid item xs={4}>
                                        <Paper elevation={3}>
                                            <Typography variant="h6" mt={0} p={1} align="center" bgcolor={"#eee"}>
                                                Dev
                                            </Typography>
                                            <ImgWithModal src={item.dev.image} alt="Development" style={{ width: '100%', cursor: 'pointer' }} />
                                        </Paper>
                                    </Grid>
                                    <Grid item xs={4}>
                                        <Paper elevation={3}>
                                            <Typography variant="h6" mt={0} p={1} align="center" bgcolor={"#eee"}>
                                                Diff
                                            </Typography>
                                            <ImgWithModal src={item.diff.image} alt="Diff" style={{ width: '100%', cursor: 'pointer' }} modalsx={{ background: '#fff'}} />
                                        </Paper>
                                    </Grid>
                                </Grid>
                            </AccordionDetails>
                        </Accordion>
                    ))}
                    <Typography variant="h5" gutterBottom mt={8}>
                        Passed Audit ({reportData?.success?.length || 0} passed)
                    </Typography>
                    <Divider />
                    {reportData?.success && reportData.success.map((item, index) => (
                        <div key={index} style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: '10px' }}>
                            <Typography sx={{ width: '33%', flexShrink: 0 }}>
                            {item.prod.url}
                            </Typography>
                            <span>Difference: {parseFloat(item.diff.percent).toFixed(2)}%</span>
                            <span style={{ width: '155px', display: 'flex', alignItems: 'center' }}>
                                <CheckCircle sx={{ color: 'green', marginRight: 2, }} /> Audit Passed
                            </span>
                        </div>
                    ))}           
                    </React.Fragment>
                )}
                {tabValue === 2 && (
                    <React.Fragment>
                    <Typography variant="h5" mt={4} gutterBottom>
                        Total Urls Examined: ({reportData?.sitemapStats?.totalUrls || 'N/A'})
                    </Typography>
                    <Divider />
                    <Typography variant="h5" gutterBottom mt={8}>
                        Unhealthy Urls Encountered: ({reportData?.sitemapStats?.unhealthPercentage || 0}%)
                    </Typography>
                    <Divider />
                    {reportData?.sitemapStats?.unhealthyUrls && reportData.sitemapStats.unhealthyUrls.map((item, index) => (
                        <div key={index} style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: '10px' }}>
                            <Typography sx={{ width: '33%', flexShrink: 0 }}>
                            {item}
                            </Typography>
                            <span style={{ width: '155px', display: 'flex', alignItems: 'center' }}>
                                <CheckCircle sx={{ color: 'red', marginRight: 2, }} /> Url Failed
                            </span>
                        </div>
                    ))}           
                    </React.Fragment>
                )}
            </Box>
        </div>
    );
}

export default Report;