import React from 'react';
import { Snackbar, Alert } from '@mui/material';
import { useReportGenerator } from './context';

export default function ReportSnackbar() {
    const { error, handleClose } = useReportGenerator();
    return (
        <Snackbar open={!!error} autoHideDuration={10000} onClose={handleClose}>
            <Alert onClose={handleClose} severity="error" sx={{ width: '100%' }}>
            {error}
            </Alert>
        </Snackbar>
    )
}