import React, { useState } from 'react';
import { Modal } from '@mui/material';

const ImgWithModal = ({ src, alt, ...props }) => {
  const [open, setOpen] = useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <img src={src} alt={alt} {...props} onClick={handleOpen} />
      <Modal
        open={open}
        onClose={handleClose}
        style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
      >
        <img src={src} alt={alt} style={{ maxHeight: '80vh', maxWidth: '80vw', ...props.modalsx }} />
      </Modal>
    </div>
  );
};

export default ImgWithModal;