import React from 'react';
import { Typography, Button } from '@mui/material';
import { useNavigate } from 'react-router';
import { useReportGenerator } from '../context';

export const Complete = () => {
    const navigate = useNavigate();
    const { 
      IsGeneratedUID
    } = useReportGenerator();
    return (
      <React.Fragment>
        <Typography variant="h5">Report Generated!</Typography>
        {IsGeneratedUID && (
          <Button variant="contained" color="success" onClick={() => navigate(`/report/${IsGeneratedUID}`)}>
            View Report
          </Button>
        )}
      </React.Fragment>
    )
  }